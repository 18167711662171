<template>
  <div>
    <div class="entry">
      <h3>
        <img
          class="logo"
          src="@/img/logo.png"
          alt="インテリジェントヘルスケア ストレスチェックApp"
        />
      </h3>
      <h3 class="uk-text-background">保留中の依頼</h3>
      <div
        v-show="
          !$store.state.common.sIsLoading && !$store.state.common.sDataLoading
        "
      >
        <div class="uk-container uk-width-3-4 uk-margin">
          <ul class="request_lists uk-list uk-margin-remove">
            <li v-for="li in requests" :key="li.sc_id">
              <a style="font-size: 1.5em" @click="resume_request(li.sc_id)"
                >{{ li.crt_date }} 作成分</a
              >
            </li>
          </ul>
        </div>

        <div class="uk-container uk-flex uk-flex-center uk-flex-middle">
          <div class="uk-width-1-3">
            <button
              type="button"
              name="button"
              @click="$router.back()"
              class="uk-button uk-button-default uk-width-1-1"
            >
              &lt;&nbsp;戻る
            </button>
          </div>
          <div class="uk-width-1-3 uk-margin-small-left">
            <button
              type="button"
              name="button"
              @click="$router.push('/client-request_stresscheck')"
              class="uk-button uk-button-primary uk-width-1-1"
            >
              新規作成
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  data() {
    return {
      requests: "",
    };
  },
  mounted() {
    this.$store.state.common.sIsLoading = false;
    document.title = "保留中の依頼";
  },
  created() {
    this.$store.state.common.sIsLoading = true;
    this.$store.state.common.sDataLoading = true;
    this.checkLogined();
    this.get_requests();
  },
  methods: {
    checkLogined() {
      if (!this.$store.state.login_status_c) {
        this.$router.replace("/client-login");
      }
    },
    resume_request(id) {
      this.$store.commit("setImplementationID", {
        id: id,
      });
      this.$router.push("/client-request_stresscheck");
    },
    get_requests() {
      this.$axios
        .post("/client/get_requests", {
          alias_id: this.$store.state.alias_id,
          email: this.$store.state.email,
          pw: this.$store.state.pass,
        })
        .then(
          function (response) {
            //eslint-disable-line
            if (response.data.flg) {
              if (response.data.request_list.length != 0) {
                this.requests = response.data.request_list;
              }
              this.$store.state.common.sDataLoading = false;
            } else {
              if (response.data.status == 3) {
                alert("不正な操作です。お手数ですが再度やり直してください。");
                this.$store.state.common.sDataLoading = false;
                this.$router.replace("/error");
              }
            }
          }.bind(this)
        )
        .catch(
          function (error) {
            console.log(error);
            this.$store.state.common.sDataLoading = false;
          }.bind(this)
        );
    },
  },
};
</script>
